import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation, withTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { dataAccessUser } from 'data-access/user/data-access-user'

import { type ParticipantType } from 'util/search/constants/participant'
import { userStore } from 'util/core/store/user-store'
import { authService } from 'util/core/service/auth.service'
import { appConfig } from 'util/core/config/app-config.instance'

import LanguageToggler from 'ui/core/header/language-toggler/language-toggler'
import { UserIcon } from 'ui/core/icons'
import UiHeader from 'ui/core/header/header'
import NavItem from 'ui/core/header/nav-item/nav-item'

interface MenuItem {
  title?: string
  link?: string
  types?: ParticipantType[]
  sub?: MenuItem[]
  divider?: boolean
  target?: string
  langToggler?: any
  external?: boolean
}

const Header: React.FC = () => {
  const { t } = useTranslation()
  const [mobile, setMobile] = useState<boolean>(window.innerWidth <= 991 || false)
  const [user, setUser] = useState<any>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { pathname } = useLocation()

  const loadUser = async (): Promise<void> => {
    try {
      const userData = await dataAccessUser.getProfile()
      const parsedUser = {
        ...userData,
        cpvNotificationList: userData.cpvNotificationList.length ? JSON.parse(userData.cpvNotificationList) : ''
      }
      setUser(parsedUser)
      userStore.update({ user: parsedUser })
    } catch (error: any) {}
  }

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    if (authService.isLoggedIn) {
      void loadUser()
    }
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const updateDimensions = (): void => {
    if (window.innerWidth <= 991 && !mobile) {
      setMobile(true)
    } else if (window.innerWidth > 991 && mobile) {
      setMobile(false)
    }
  }

  const changeLanguage = (newLng: string): void => {
    if (newLng === i18n.language) return

    const oldBasename = (i18n.language === appConfig.get('defaultLanguage')) ? '/' : `/${i18n.language}/`
    const newBasename = (newLng === appConfig.get('defaultLanguage')) ? '/' : `/${newLng}/`

    let newPathname = location.pathname

    // Якщо шлях закінчується на стару мову без слеша, додаємо слеш
    if (!newPathname.endsWith('/')) {
      newPathname += '/'
    }

    // Замінюємо стару мову на нову
    newPathname = newPathname.replace(oldBasename, newBasename)

    window.location.replace(newPathname)
  }

  const mainMenu: MenuItem[] = [
    {
      title: t('Про компанію'),
      link: `${appConfig.get('NEWS')}/pro-kompaniyu/`,
      external: true
    },
    {
      title: t('Корисне'),
      sub: [
        {
          title: t('Інструкції'),
          link: `${appConfig.get('NEWS')}/category/instrukcziyi/`,
          target: '_blank',
          external: true
        },
        {
          title: t('Законодавство'),
          link: `${appConfig.get('NEWS')}/category/zakonodavstvo/`,
          target: '_blank',
          external: true
        },
        {
          title: t('Новини'),
          link: `${appConfig.get('NEWS')}/category/novini/`,
          target: '_blank',
          external: true
        }
      ]
    },
    {
      title: t('Контакти'),
      link: '/info/contacts',
      target: '_self'
    },
    {
      langToggler: <LanguageToggler onChangeLang={changeLanguage} />
    },
    { divider: true }
  ]

  const userMenu: MenuItem[] = authService.isLoggedIn
    ? [
        {
          title: t('Дашборд'),
          link: '/cabinet/dashboard',
          types: ['seller', 'buyer', 'cpe']
        },
        {
          title: t('Закупівлі'),
          types: ['seller', 'buyer', 'cpe'],
          sub: [
            {
              title: t('Локалізовані товари'),
              link: '/cabinet/tenders/localized-products',
              types: ['seller']
            },
            {
              title: t('Збережені пошуки'),
              link: '/cabinet/tenders/saved-filters',
              types: ['seller']
            },
            {
              title: t('Заплановані'),
              link: '/cabinet/tenders/scheduled',
              types: ['seller']
            },
            {
              title: t('Подані'),
              link: '/cabinet/tenders/submitted',
              types: ['seller']
            },
            {
              title: t('Очікують підписання'),
              link: '/cabinet/tenders/awaiting',
              types: ['seller']
            },
            {
              title: t('Отримані завдання'),
              link: '/cabinet/tenders/received-tasks',
              types: ['seller']
            },
            {
              title: t('Направлені завдання'),
              link: '/cabinet/tenders/directed-tasks',
              types: ['seller']
            },
            {
              title: t('Чернетки'),
              link: '/cabinet/tenders/drafts',
              types: ['seller', 'buyer', 'cpe']
            },
            {
              title: t('Оголошені'),
              link: '/cabinet/tenders/announced',
              types: ['buyer', 'cpe']
            },
            {
              title: t('Очікують'),
              link: '/cabinet/tenders/expecting',
              types: ['buyer']
            }
          ]
        },
        {
          title: t('Плани'),
          types: ['buyer', 'cpe'],
          sub: [
            {
              title: t('Чернетки'),
              link: '/cabinet/plans/drafts',
              types: ['buyer', 'cpe']
            },
            {
              title: t('Погодження'),
              link: '/cabinet/plans/coordination',
              types: ['buyer']
            },
            {
              title: t('Опубліковані'),
              link: '/cabinet/plans/published',
              types: ['buyer', 'cpe']
            }
          ]
        },
        {
          title: t('Налаштування'),
          types: ['seller', 'buyer', 'cpe'],
          sub: [
            {
              title: t('Профіль користувача'),
              link: '/cabinet/profile',
              types: ['seller', 'buyer', 'cpe']
            },
            {
              title: t('Безпека та пароль'),
              link: '/cabinet/profile/security',
              types: ['seller', 'buyer', 'cpe']
            }
          ]
        },
        { divider: true }
      ]
    : []

  const filteredMenu = (userMenu: MenuItem[], type?: ParticipantType): any => {
    let menu = userMenu
    if (type) {
      menu = userMenu.map((section: any) => {
        if ('sub' in section) {
          return {
            ...section,
            sub: section.sub ? section.sub.filter((item: any) => item.types?.includes(type)) : undefined
          }
        }
        return section
      }).filter((section: any) => {
        if ('sub' in section) {
          return section.sub && section.sub.length > 0
        }
        return true
      })
    }

    return menu
  }

  const toggleMenu = (): void => {
    setIsOpen((prevIsOpen: boolean) => !prevIsOpen)
  }

  const login = authService.isLoggedIn
    ? {
        title: t('Вихід'),
        onClick: () => { authService.logout() }
      }
    : (mobile
        ? {
            title: <><UserIcon /> {t('Увійти')}</>,
            link: '/login',
            state: { from: pathname }
          }
        : []
      )

  // const menu = [...filteredMenu(userMenu, user?.type), ...mainMenu, login]   // removed userMenu on release 2.0.0
  const menu = [...mainMenu, login]

  return (<>
    <UiHeader mobile={mobile} menu={menu}>
      {mainMenu.filter((item: any) =>
        !item.divider && !item.langToggler).map((item: any) =>
          <NavItem key={item.title}
            title={item.title}
            link={item.link}
            external={item.external}
            menu={item.sub}
            open={isOpen}
            target={item.target}
            onClick={() => toggleMenu}
            onClickAway={() => {
              setIsOpen(false)
            }}
          />
      )}
      <LanguageToggler onChangeLang={changeLanguage} />
    </UiHeader>
  </>)
}

export default withTranslation('dzo')(Header)
