import { BehaviorSubject } from 'rxjs'

class UserStore {
  public readonly defaultUser: any = null

  private _user: any = this.defaultUser

  private readonly userSubject = new BehaviorSubject<any | null>(null)

  public readonly userPublisher = this.userSubject.asObservable()

  public get user (): any {
    return this._user
  }

  public update (state: any) {
    if (state.user) {
      this._user = { ...this._user, ...state.user }
      this.userSubject.next(this._user)
    }
  }

  public clear () {
    this._user = { ...this.defaultUser }
    this.userSubject.next(this.defaultUser)
  }
}

export const userStore = new UserStore()
