import styled from 'styled-components'

export const Wrap = styled.footer`
  position: relative;
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  background-color: ${props => props.theme.color.white};  
  @media screen and (max-width: ${props => props.theme.resolution.sm}) {
    gap: 24px;
    padding: 24px;
  }
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`
export const Menu = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  gap: 40px;
  @media screen and (max-width: ${props => props.theme.resolution.sm}) {
    width: 100%;
    gap: 24px;
    flex-wrap: wrap;
    border: 1px dashed ${props => props.theme.color.linkwater};
    border-left: none;
    border-right: none;
    padding: 16px 0;
  }
`
export const MenuItem = styled.li`
  @media screen and (max-width: ${props => props.theme.resolution.sm}) {
    width: calc(50% - 16px);
  }
  a {
    ${props => props.theme.typography.p3};
    color: ${props => props.theme.color.black};
    text-decoration: none;
  }
`
export const Copyright = styled.div`
  ${props => props.theme.typography.p2};
  color: ${props => props.theme.color.dimgrey};
  @media screen and (max-width: ${props => props.theme.resolution.sm}) {
    order: 2;
    width: 100%;
    color: ${props => props.theme.color.dimgrey};
    ${props => props.theme.typography.p6};
  }
`
