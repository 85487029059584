import React from 'react'
import * as Styled from './footer.styled'
import { Link } from 'react-router-dom'
import i18n from 'i18next'
import { withTranslation } from 'react-i18next'
import { type UtilWithTranslationProps } from 'util/core/util-with-translation-props'
import UiFollowUs from '../../follow-us/ui-follow-us'

interface UiFooterProps extends UtilWithTranslationProps {
  logoPaths: Record<string, string>
  menu: Array<{
    name: string
    link: string
    outerLink?: boolean
  }>
}

const UiFooter: React.FC<UiFooterProps> = ({ logoPaths, menu, t }) =>
  <Styled.Wrap>
    <Styled.Row>
      <Link to={'/'}>
        <img src={logoPaths[i18n.language]} style={{ height: '40px' }} alt={t('Державні закупівлі онлайн')}/>
      </Link>
      <Styled.Menu>
          {menu.map(item => <Styled.MenuItem key={item.name}>
            {item.outerLink
              ? <a href={item.link} target='_blank' rel="noopener noreferrer nofollow">{item.name}</a>
              : <Link to={item.link}>{item.name}</Link>}
          </Styled.MenuItem>)}
      </Styled.Menu>
    </Styled.Row>
    <Styled.Row>
      <Styled.Copyright>© dzo.com.ua, 2014 - {(new Date()).getFullYear()}</Styled.Copyright>
      <UiFollowUs />
    </Styled.Row>
  </Styled.Wrap>

export default withTranslation('dzo')(UiFooter)
