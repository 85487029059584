import styled from 'styled-components'
import { ExternalLink } from '../external-link/external-link-component'
import { Link } from 'react-router-dom'

export const Wrap = styled.div`
  width: 100%;
  padding: 16px 40px;
  color: ${props => props.theme.color.black};
  position: relative;
  z-index: 5;
  @media screen and (max-width: ${props => props.theme.resolution.sm}) {
    padding: 8px;
  }
`
export const Content = styled.div<{ open?: boolean }>`
  height: 60px;
  max-width: ${props => props.theme.resolution.xxxl};
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  gap: 64px;
  background: ${props => props.theme.color.white};
  border-radius: 16px;
  padding: 8px 24px;
  box-shadow: ${props => props.theme.color.boxshadow};
  position: relative;
  @media screen and (max-width: ${props => props.theme.resolution.lg}) {
    ${props => props.open ? 'border-radius: 16px 16px 0 0' : ''};
  }
  @media screen and (max-width: ${props => props.theme.resolution.sm}) {
    height: 52px;
    padding: 10px 12px;
  }
`
export const Nav = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 64px;
  & > *:first-child, & > *:last-child {
    margin-left: auto;
  } 
  @media screen and (max-width: ${props => props.theme.resolution.xxl}) {
    gap: 36px;
  }
`
export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 6px;
  ${props => props.theme.typography.p1};
  text-decoration: none;
  color: ${props => props.theme.color.black};
  cursor: pointer;
  margin-left: auto;
  @media screen and (max-width: ${props => props.theme.resolution.lg}) {
    margin-left: 0;
  }
`
export const StyledOuterLink = styled(ExternalLink)`
  display: flex;
  align-items: center;
  gap: 6px;
  ${props => props.theme.typography.p1};
  text-decoration: none;
  color: ${props => props.theme.color.black};
  cursor: pointer;
  margin-left: auto;
  @media screen and (max-width: ${props => props.theme.resolution.lg}) {
    margin-left: 0;
  }
`
export const User = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`
