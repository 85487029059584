import React from 'react'
import * as Styled from './logo.styled'
import { LogoEn, LogoProzoro, LogoUk } from '../../logos'
import { useTranslation, withTranslation } from 'react-i18next'
import i18n from 'i18next'

const logoPaths: Record<string, string> = {
  uk: LogoUk,
  en: LogoEn
}

const Logo: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Styled.Wrap>
      <Styled.StyledLink to={'/'}>
        <img src={logoPaths[i18n.language]} alt={t('Державні закупівлі онлайн')}/>
      </Styled.StyledLink>
      <Styled.StyledExternalLink>
        <img src={LogoProzoro} alt="Prozorro"/>
      </Styled.StyledExternalLink>
    </Styled.Wrap>
  )
}

export default withTranslation('dzo')(Logo)
