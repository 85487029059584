import React, { type ReactNode } from 'react'
import { appConfig } from 'util/core/config/app-config.instance'

interface ExternalLinkProps {
  className?: string
  title?: string
  href?: string
  children: any
  oldDzo?: boolean
  auctions?: boolean
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({ className, href, title, children, oldDzo, auctions }) => {
  const prozorroLink = appConfig.get('PROZORRO')
  const oldDzoLink = appConfig.get('OLD_DZO')

  const link = oldDzo ? oldDzoLink : auctions ? '' : prozorroLink

  return (
    <a
      className={className}
      href={`${link}${href ?? ''}`}
      title={title ?? 'Prozorro'}
      target={oldDzo ? '' : '_blank'}
      rel="noopener noreferrer nofollow"
    >
      {children}
    </a>
  )
}
